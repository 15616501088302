
























































@import '../../utilities/config';

.m-inline-confirmation-btn {
  //display: flex;
  position: relative;
  &__confirm {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translate(0, -33%);
  }
}
