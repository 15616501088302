


































































































































































































































































































































































@import '../../utilities/config';

.o-buyer {
  & + & {
    margin-top: 60px;
  }
}
